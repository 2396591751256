import React from 'react'
import {Row, Col, Container } from 'react-bootstrap'
import './features.sass'

const Featuresmid = ({ heading, heading2, description, description2, caption, link, image, image2 }) => (
  <section className="features-mid-block" id="features-mid">
     <Container>
        <Row>
          <Col md={6}>
              <div className="features-mid-left workflow-description">
                <div className="amazing-dashboard-left">
                  <h2 className="mb-2">{heading}</h2>
                  <span className="section-title-line mb-4"></span>
                  <p className="mb-5">{description}</p>
                </div>
              </div>
          </Col>
          <Col md={6}>
            <div className="amazing-dashboard">
              <img className="amazing-dashboard right-overflow" src={image2} alt={heading2} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <div className="amazing-dashboard">
               <img className="amazing-dashboard left-overflow extra-height" src={image} alt={heading} />
            </div>
          </Col>
        <Col md={6}>
          <div className="features-mid-right workflow-description">
            <div className="amazing-dashboard-right">  
              <h2 className="mb-2">{heading2}</h2>
              <span className="section-title-line mb-4"></span>
              <p className="mb-5">{description2}</p>
            </div>
         </div>
         </Col>
        </Row>
     </Container>
  </section>
)

export default Featuresmid
