import React from 'react'
import { Container } from 'react-bootstrap'
import './specialties.sass'

const Specialities = ({ heading, heading2, description, description2, image, image2, gridItems }) => (
   <section className="amazing-dashboard-block" id="amazing-dashboard">
      <Container>
         <div className="row">
            <div className="col-md-6">
               <div className="amazing-dashboard-left">
                  <img className="amazing-dashboard left-overflow" src={image} alt={heading} />
               </div>
            </div>
            <div className="col-md-6 workflow-description">
               <div className="amazing-dashboard-right">
                  <h2 className="mb-2">{heading}</h2>
                  <span className="section-title-line mb-4"></span>
                  <p>{description}</p>
                  {/*gridItems.map(item => (
                  <div>
                     <h5>{item.title}</h5>
                     <p>{item.text}</p>
                  </div>
                  ))*/}
               </div>
            </div>
         </div>
         <div className="row">
            <div className="col-md-6 workflow-description">
               <div className="amazing-dashboard-left">
                  <h2 className="mb-2">{heading2}</h2>
                  <span className="section-title-line mb-4"></span>
                  <p>{description2}</p>
               </div>
            </div>
            <div className="col-md-6">
               <div className="amazing-dashboard-right">
                  <img className="amazing-dashboard right-overflow" src={image2} alt={heading2} />
               </div>
            </div>
         </div>
      </Container>
   </section>
)

export default Specialities
