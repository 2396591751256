import React from 'react'
import {Row, Col, Container } from 'react-bootstrap'
import './features.sass'

const FeaturesBottom = ({ heading, description, caption, link, image }) => (
   <section class="features-bottom-block">
      <Container>
         <Row>
            <Col md={6}>
               <div class="amazing-dashboard-left">
               <img className="amazing-dashboard left-overflow extra-height" src={image} alt={heading} />
               </div>
            </Col>
            <Col md={6}>
               <div class="features-bottom-right pt-4">
                  <h2 class="mb-2">{heading}</h2>
                  <span class="section-title-line mb-4"></span>
                  <p class="mb-5">{description}</p>
               </div>
            </Col>
         </Row>
      </Container>
   </section>
)


export default FeaturesBottom
