import React, { useRef, useState } from 'react';
import {Row, Col, Container } from 'react-bootstrap'
import './subscribe.sass'

import { TiSocialFacebook } from "react-icons/ti";
import { TiSocialTwitter } from "react-icons/ti";
import { TiSocialInstagram } from "react-icons/ti";
import { TiSocialLinkedin } from "react-icons/ti";
import { TiSocialYoutube } from "react-icons/ti";

const Subscribe = ({ props }) => {
  const formRef = useRef(null);
  const [feedback, setFeedback] = useState(null);
  
  const handleSubmit = async (e) => {
      e.preventDefault();
      const data = new FormData(formRef.current);
      try {
          const response = await fetch(formRef.current.action, { method: 'POST', body: data });
          if (response.ok) {
              setFeedback({
                message: 'Thank you for signing up! Please check your email for confirmation. You can now download Sashimi for your Mac:',
                status: 'success',
              });
          } else {
              throw new Error('Encountered an error');
          }
      } catch (error) {
          setFeedback({ message: 'An error occurred. Please try again.', status: 'error' });
          console.log(error);
      }
  };

  const disableForm = (feedback && feedback.status === 'success') ? true : false;

  return (
    <section className="sign-up-block" id="sign-up">
      <Container>
        <div className="section-title text-center">
           <span className="badge badge-primary">Sign Up to Download</span>
           <h2>Sign Up to Sashimi Beta</h2>
           <span className="section-title-line"></span>
        </div>
        <Row>
        <Col md={6} className="mx-auto">
            <div className="sign-up-form">
                <form ref={formRef} onSubmit={handleSubmit} action="https://listmonk.sashimiapp.com/subscription/form" className="listmonk-form">
                  <div className="input-group">
                    <input type="hidden" name="nonce" />
                    {  disableForm && (<input name="email" type="email" required="true" placeholder="Your email address" className="form-control" disabled/>)}
                    { !disableForm && (<input name="email" type="email" required="true" placeholder="Your email address" className="form-control"/>)}
                    <input name="name" type="text" placeholder="Your name (optional)" className="form-control" hidden="true" />
                    <input id="l-4617911c-71a2-4bc9-899c-4215ba84bf3a" type="checkbox" name="l" checked="true" value="4617911c-71a2-4bc9-899c-4215ba84bf3a" hidden="true" />
                    <div className="input-group-prepend">
                      {  disableForm && (<button className="btn btn-primary" type="submit" disabled>Sign up</button>)}
                      { !disableForm && (<button className="btn btn-primary" type="submit">Sign up</button>)}
                    </div>
                  </div>
                </form>
                {feedback && (
                <div id="feedback" className={feedback.status} className="text-center">
                  <p>{feedback.message}</p>
                  {feedback.status === 'success' && (<p><a class="download" href='https://sashimiapp.com/download/build/beta'><img class="download" src="/img/download.svg"/> Download Sashimi Beta</a></p>)}
                </div>
                )}
                {!feedback && (
                  <p className="mt-3 text-center">Sign up for a Sashimi Beta download link, and for updates on further developments towards Sashimi 1.0.</p>
                )}
                {/* Social media links */}
                {/*
                <div class="container">
                  <div class="social-icons mb-3 py-2 text-center">
                    <a rel="noopener noreferrer" target="_blank" href="https://www.facebook.com/sashimiapp">
                      <TiSocialFacebook/>
                    </a>
                    <a rel="noopener noreferrer" target="_blank" href="https://www.instagram.com/sashimi_app">
                      <TiSocialInstagram/>
                    </a>
                    <a rel="noopener noreferrer" target="_blank" href="https://twitter.com/sashimiapp">
                      <TiSocialTwitter/>
                    </a>
                  </div>
                </div>
                */}
            </div>
        </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Subscribe